import { Component, ViewChild, ElementRef } from '@angular/core';

import { Platform, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { User } from './models/User';
import { ApiService } from './api.service';
import { Session } from './utils/Session';
import { Storage } from '@ionic/storage';
import { Empleado } from './models/Empleado';
import { AuthToken } from './models/AuthToken';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public user : Empleado;
  public loginData = {
    id: "",
    pass : ""
  };
  private adminToken : AuthToken = null;

  public lenguageSelected = "es";

  @ViewChild("sideMenu", {static: false}) sideMenu : ElementRef;
  public staticInfo;
  public mobile;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private apiService : ApiService,
    private storage : Storage,
    private events : Events,
    private route : Router,
    private translateService: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.user = null;
      if(this.platform.is("mobile")){
        this.mobile = true;
      } else {
        this.mobile = false;
      }
      //this.getDataStorage("tokenAdmin");
      this.getDataStorage("empleado");
      this.staticInfo = false;
      this.events.subscribe("userLogin", () => {
        this.getDataStorage("empleado");
      });
      this.translateService.setDefaultLang('es');
      this.translateService.use('es');
    });
  }

   loginForm($event : any){
    if(this.user == null)
       //his.apiService.loginApi(this.loginData.id, this.loginData.pass, Session.admin.puesto, false);
       this.apiService.getFromApi(
         "/authMovil",
          [
            {
              name: "name",
              value: this.loginData.id
            },
            {
              name: "pass",
              value: this.loginData.pass
            }
          ],
         (response) => {
          this.apiService.getFromApi(
            "/empleados",
            [
              {
                name: "token",
                value: response.token
              },
              {
                name: "empleado",
                value: true
              },
              {
                name: "nif",
                value: this.loginData.id
              }
            ],
            (data) => {
              this.user = new Empleado(data);
              this.user.setToken(response.token);
              this.storage.set("empleado", this.user).then(() =>{
                this.events.publish("userLogin");
              })
            },
            false
          )
         },
         true
       )
  }

  private getDataStorage(key){
   this.storage.get(key).then((data) =>{
      if(key == "empleado"){
        if(data != null){
          this.user = new Empleado([data]);
          //this.route.navigateByUrl("/home");
        } else  
          this.user = null;
      } else if(key == "tokenAdmin"){
        if(data != null){
          console.log("Cargando Admin Token");
          this.adminToken = new AuthToken(data);
        }else 
          this.apiService.loginApi(Session.admin.id, Session.admin.pass, Session.admin.puesto, true);
      }
    }).catch((err) => {
      console.log("ELLOL!! ", err);
    })
  }


  private deleteDataStorage(key){
    this.storage.remove(key);
  }

  private cleanStorage(){
    this.storage.keys().then((keys) =>{
      for(let i=0; i<keys.length; i++){
        this.storage.remove(keys[i]);
      }
    })
  }

  public Desconectar(){
    this.apiService.signout("empleado", 
    (success) => {
      this.route.navigateByUrl("/home");
    }, (error) => {
      console.log("ELLOL "+error);
    })
  }

  private loadPAge(){
    let rot = window.location.pathname.split("/");
      this.route.navigateByUrl("/"+rot[rot.length-1]);
  }

  cambioIdioma(){
    this.translateService.use(this.lenguageSelected);
  }
}
