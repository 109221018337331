import { User } from './User';
import { Puesto } from './Puesto';
import { ThrowStmt } from '@angular/compiler';

export class Info{
    private createdBy : User;
    private puesto : Puesto;
    private enabled : boolean;

    constructor(dataInfo ? : any){
        if(dataInfo != null){
            this.enabled = dataInfo.enabled;
            this.puesto = dataInfo.puesto;
            this.createdBy = new User(dataInfo.createdBy);
        }
    }

    public getCreatedBy() : User{
        return this.createdBy;
    }

    public setCreatedBy(user : User){
        this.createdBy = user;
    }

    public getPuesto() : Puesto {
        return this.puesto;
    }

    public setPuesto(puesto : Puesto){
        this.puesto = puesto;
    }

    public isEnabled() : boolean{
        return this.enabled;
    }

    public setEnabled(enabled : boolean){
        this.enabled = enabled;
    }


}