import { List } from './List';

export class Utils{

    public static ConvertString (data) : string {
        let conversion = "?";
        for(let i=0; i<data.length; i++){
            if(i == 0)
                conversion += data[i].name + "=" + data[i].value;
            else
                conversion += "&"+data[i].name + "=" + data[i].value;
        }
        return conversion;
    }

    public static ConvertoToArray(data : List<any>) : any []{
        let conversion = [];
        for(let i=0; i<data.size(); i++){
            conversion.push(data.get(i));
        }
        return conversion;
    }

    public static deleteAccentAndExtras(text : string) : string{
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }

    public static englishToSpanish(text : string) : string{
        switch(text.toLowerCase()){
            case "monday":
                return "Lunes";
            case "tuesday":
                return "Martes";
            case "wednesday":
                return "Miércoles";
            case "thursday":
                return "Jueves";
            case "friday":
                return "Viernes";
            case "saturday":
                return "Sábado";
            case "sunday":
                return "Domingo";
        }
        return null;
    }
}