import { List } from '../utils/List';

export class AuthToken{
    private static serialVersionUID:number = -3995152217676559642;
    private token: string;
    private roles: List<Rol>;

    constructor(data : any){
        if( data != null){
            this.roles = data.roles;
            this.token = data.token;
        }
    }

    public getToken() : string{
        return this.token;
    }

    public setToken(token: string) : void {
        this.token = token;
    }

    public getRoles(): List<Rol>{
        return this.roles;
    }

    public setRoles(roles: List<Rol>) : void {
        this.roles = roles;
    }

    public hasRol(rol: Rol): boolean{
        for(let i=0; i<this.roles.size(); i++){
            if(Rol.equals(rol, this.roles.get(i))){
                return true;
            }
        }
        return false;
    }

    public toString() : string{
        return "Token: "+this.getToken()+" /nRoles: "+this.getRoles();
    }
}