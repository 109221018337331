import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { AuthToken } from './models/AuthToken';
import { Session } from './utils/Session';
import { Puesto } from './models/Puesto';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform, LoadingController, Events} from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { from } from 'rxjs';
import { Utils } from './utils/Utils';
import { Storage } from '@ionic/storage';
import { Empleado } from './models/Empleado';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private token : AuthToken = null;
  private data : any;

  constructor(
    private http: HTTP, 
    private httpB : HttpClient, 
    private platform: Platform,
    private ldCtrl : LoadingController, 
    private storage : Storage, 
    private route : Router,
    private events : Events) { 
    }

  /* Login API Mobile*/

  async loginApi(
    name : string, 
    password : string, 
    puesto : Puesto, 
    admin : boolean,
    callback?){
    console.log("Logeando a la API");
    let token : AuthToken = null;
    try{
      const dataWeb = [
        {
          name : "name",
          value: name
        },
        {
          name: "pass",
          value: password
        },
        {
          name: "puesto",
          value: puesto
        }
      ]
      const dataApp = {
        "name": name,
        "pass": password,
        "puesto": puesto
      };  

      const headers = {};
      if(this.platform.is('desktop')){
        this.loginWeb(dataWeb, admin, callback);
      } else if(this.platform.is('mobile')){
        this.loginMobile(dataWeb, admin);
      }
    }catch(error){  
      console.log("Toma Error!!!");
    }
  }

  public signout(key, success, err){
    this.storage.remove(key).then(success).catch(err);
  }


  /* MEtodo para login en WEB */
  private async loginWeb(dataWeb, admin, callback?){
    //let loading = await this.ldCtrl.create();
    try{
      //await loading.present();
        console.log("Ejecutando en Ordenador");
        console.log("Enviando Petición");
        this.httpB.post(Session.url+"/auth"+Utils.ConvertString(dataWeb), {}).pipe(
          finalize(() => console.log(""))
        )
        .subscribe(data=> {
          //loading.dismiss();
          if(admin){
            console.log("Logueando al Admin");
            this.addDataStorage(data, "tokenAdmin");
          }else{
            console.log("Logueando usuario");
            this.addDataStorage(data, "token");
            this.retrieveUserData(dataWeb[0].value);
          }
          
        }, err => {
          finalize(() => console.log("finalizando con error"));
        });
      }catch(error){  
        console.log("Error de Conexión");
      }
  }

  /* Método para login en App Móbil */
  private async loginMobile(data, admin){
    console.log("Ejecutando en Dipositivo Movil");
        console.log("Enviando Petición");


        let nativeCall = this.http.post(Session.urlApi+Utils.ConvertString(data), {}, {
          'Content-Type': 'application/json'
        });

        from(nativeCall).pipe(
          finalize(() => console.log(""))
        )
        .subscribe(data => {
          console.log('native data: ', data);
          this.data = JSON.parse(data.data).results;
        }, err => {
          console.log("JS call error: '", err);
        });
  }

  // Método de Obtención de datos de usuario

  private async retrieveUserData(dni : string){
    console.log("Obteniendo datos del empleado");
    if(this.platform.is('desktop')){
      this.getUserDataWeb(dni);
    }else if(this.platform.is('mobile')){
      this.getUserDataApp();
    }
    
  }

  // Método de obtencion de datos de usuario en Web
  private async getUserDataWeb(dni : string){
    this.getDataStorage("token");
    let loading = await this.ldCtrl.create();
    await loading.present();
    console.log("Token del empleado "+this.data.getToken());
    let dataUser = [

      {
        name: "token",
        value: this.data.getToken()
      },
      {
        name: "nif",
        value: dni
      },
      {
        name: "empleado",
        value: true
      }
    ];
    this.httpB.get(Session.url+"/empleados"+Utils.ConvertString(dataUser), {}).pipe(
    ).subscribe((userData: Empleado) =>{
      loading.dismiss();
      this.addDataStorage(userData, "empleado");
    })
  }

  // Método de obtención de datos de usuario en app Móbil
  private async getUserDataApp(){

  }

  /* Consulta Storage */

  public addDataStorage(data: any, key : string){
    if(this.getDataStorage(key) == null || this.getDataStorage(key) == undefined){
      console.log("Guardando datos");
      this.storage.set(key, data).then(() =>{
        if(key == "empleado"){
          console.log("Lanzando evnto");
          this.events.publish("userLogin");
        }
      }).catch((err) => {
        console.log("ELLOL " + err);
        return false;
      });
    }
  }

  public getDataStorage(key : string, callback?) {
     this.storage.get(key).then((val) => {
       switch(key){
        case "token":
        case "adminToken":
          this.data = new AuthToken(val);
          break;
        case "empleado":
          if(val != null){
            this.data = new Empleado([val]);
            if(callback != null)
              callback(val);
          }
          
          break;
       }
    });
  }

  public async getPlanningData(dni, token, callback){
    let loading = await this.ldCtrl.create();
    await loading.present();
    const dataWeb = [
      {
        name : "dni",
        value: dni
      },
      {
        name: "token",
        value: token
      }
    ]
    this.httpB.post(Session.url+"/plannings/app"+Utils.ConvertString(dataWeb), {}).pipe(
      finalize(() => loading.dismiss())
    ).subscribe(callback);
  }

  /* Métodos de Consulta a la Api */

  async getExcursions(isla : string, idioma : string, callback){
    const url = Session.url+'/excursiones';
    try{
      const headers = {};
      const params = [
       {
         name: "token",
         value: ''
       },
       {
         name: 'isla',
         value: isla
       },
       {
         name: 'idioma',
         value: idioma
       }
      ];
      await this.httpB.get(Session.url+"/web"+Utils.ConvertString(params), {}).pipe()
      .subscribe(callback);
    } catch(error){
      return error;
    }
  }

  async getFromApi(clase : string, parametros, callback, post?){
    const url = Session.url+clase;
    if(!post){
      try{
        await this.httpB.get(url+Utils.ConvertString(parametros), {}).pipe()
        .subscribe(callback);
      }catch(error){
        return error;
      }
    } else {
      try{
        await this.httpB.post(url+Utils.ConvertString(parametros), {}).pipe()
        .subscribe(callback);
      }catch(error){
        return error;
      }
    }
  }
}
