import { List } from '../utils/List';
import {Puesto } from '../models/Puesto';
import { AuthToken } from './AuthToken';

export class User{
    private id: number;
    private name: string;
    private password: string;
    private enabled: boolean;
    private creationTime: string;
    private permiso : boolean;
    

    constructor(userData ? : any){
       if(userData != null){
           this.id = userData.id;
           this.name = userData.nombre;
           this.password = userData.password;
           this.enabled = userData.enabled;
           this.creationTime = userData.creationTime;
           this.permiso = userData.permiso;
       }
    }

    public getID() : number{
        return this.id;
    }
    public setIndex(id: number) : void{
        this.id = id;
    }
    public getNomre() : string{
        return this.name;
    }
    public setNombre(nombre: string) : void{
        this.name = nombre;
    }
    public getPassWord() : string{
        return this.password;
    }
    public setPassWord(password: string) : void{
        this.password = password;
    }
    public getEnabled() : boolean{
        return this.enabled;
    }
    public setEnabled( enabled : boolean) : void{
        this.enabled = enabled;
    }
   
    public getTime() : string{
        return this.creationTime;
    }
    public setTime(creationTime : string) : void{
        this.creationTime = creationTime;
    }

    public getPermiso(){
        return this.permiso;
    }

    public setPermiso(permiso : boolean){
        this.permiso = permiso;
    }


}