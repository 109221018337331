import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule), pathMatch: 'full' },
  //{ path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'servicios', loadChildren: () => import ('./servicios/servicios.module').then(m => m.ServiciosPageModule) },
  //{ path: 'servicios', loadChildren: './servicios/servicios.module#ServiciosPageModule'},
  { path: 'excursion', loadChildren: './excursion/excursion.module#ExcursionPageModule' },
  { path: 'planning', loadChildren: () => import('./admin/conductor/conductor.module').then(m => m.ConductorPageModule)},
  { path: 'plannings', loadChildren: () => import('./admin/planning-list/planning-list.module').then(m => m.PlanningListPageModule) },
  { path: 'contacto', loadChildren: './contact/contact.module#ContactPageModule' },
  { path: 'reservas', loadChildren: './reservas/reservas.module#ReservasPageModule' },
  { path: 'excursiones', loadChildren: './excursiones/excursiones.module#ExcursionesPageModule' },
  { path: 'politicas', loadChildren: './politicas/politicas.module#PoliticasPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
