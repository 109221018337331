import * as _ from 'lodash';
export class List<T>{
    private items: Array<T>;

    constructor(){
        this.items = [];
    }

    size(): number{
        return this.items.length;
    }

    add(value: T): void {
        this.items.push(value);
    }

    get(index: number): T {
        return this.items[index];
    }

    equalsTo(object : T) : boolean{
        return null;
    }

    contains( object : T) : boolean{
        return _.includes(this.items, object);
    }
}