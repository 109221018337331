import { User } from '../models/User';
import { Puesto } from '../models/Puesto';
import { AuthToken } from '../models/AuthToken';
export class Session {
    public static usuario : User = new User();
    public static puesto: Puesto;
    public static adminToken: AuthToken;
    //public static url : string = "http://localhost:8000/api-viajestara";
    //public static urlApi : string = "http://10.0.3.2:8000/api-viajestara/auth";
    public static url : string = "http://api.viajestara.es";
    public static urlApi : string = "http://api.viajestara.es/auth";
    public static captchKey = "6LeTJiwaAAAAANzaM8_DIhzP0gN9dglnouFk5i-x";
    public static googleCapthURL = "https://www.google.com/recaptcha/api/siteverify";

    public static admin = {
        id: "root",
        pass: "Nsm35004",
        puesto: Puesto.GRAN_CANARIA
    };

    public static webUser = {
        id: "web",
        pass: "Nsm35004",
        puesto: Puesto.ALL
    }
}