import { Info } from './Info';

export class Idioma{
    private id : number;
    private info : Info;
    private creationTime : string;
    private descripcion : string;
    
    constructor(idiomaData ? : any){
        if(idiomaData != null){
            this.id = idiomaData.id;
            this.info = new Info(idiomaData.info);
            this.creationTime = idiomaData.creationTime;
        }
    }

    public getId() : number{
        return this.id;
    }

    public setId(id : number){
        this.id = id;
    }

    public getInfo() : Info{
        return this.info;
    }

    public setInfo(info : Info){
        this.info = info;
    }

    public getCreationTime() : string {
        return this.creationTime;
    }

    public setCreationTime(creationTime : string){
        this.creationTime = creationTime;
    }

    public getDescripcion() : string{
        return this.descripcion;
    }

    public setDescripcion( descripcion : string){
        this.descripcion = descripcion;
    }
}