import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule } from '@ionic/angular';


 //Custom Components 
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { FooterComponent } from './footer/footer.component';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [
      CommonModule
    ],
    exports:[FooterComponent],
    declarations: [FooterComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
  })
  export class ComponentsModule {}