import { List } from '../utils/List';
import { Idioma } from './Idioma';
import { Info } from './Info';

export class Empleado{
    private id : number;
    private nombre : string;
    private tipoEmpleado : List<TipoEmpleado>;
    private idiomas : List<Idioma>;
    private dni : string;
    private info : Info;
    private creationTime : string;
    private token : string;

    constructor(empleadoData? :any){
        if(empleadoData != null || empleadoData[0] != null){

            this.id = empleadoData[0].id;
            this.nombre = empleadoData[0].nombre;
            this.dni = empleadoData[0].dni;
            this.creationTime = empleadoData[0].creationTime;
            this.tipoEmpleado = new List<TipoEmpleado>();
            for(let i=0; i<empleadoData[0].tipoEmpleado.length; i++){
                this.tipoEmpleado.add(empleadoData[0].tipoEmpleado[i]);
            }
            this.idiomas = new List<Idioma>();
            for(let i=0; i<empleadoData[0].idiomas.length; i++){
                let idioma = new Idioma(empleadoData[0].idiomas[i]);
                this.idiomas.add(idioma);
            }
        }
    }

    public getId(){
        return this.id;
    }

    public setId(id : number){
        this.id = id;
    }

    public getNombre(){
        return this.nombre;
    }

    public setNombre(nombre : string){
        this.nombre = nombre;
    }

    public getTipoEmpleado(){
        return this.tipoEmpleado;
    }

    public setTipoEmpleado(tipoEmpleado : List<TipoEmpleado>){
        this.tipoEmpleado = tipoEmpleado;
    }

    public getIdiomas(){
        return this.idiomas;
    }

    public setIdiomas(idiomas : List<Idioma>){
        this.idiomas = idiomas;
    }

    public getDni(){
        return this.dni;
    }

    public setDni(dni : string){
        this.dni = dni;
    }

    public getInfo(){
        return this.info;
    }

    public setInfo(info : Info){
        this.info = info;
    }

    public getCreationTime(){
        return this.creationTime;
    }

    public setCreationTime(creationTime : string){
        this.creationTime = creationTime;
    }

    public getToken() : string {
        return this.token;
    }

    public setToken(token : string){
        this.token = token;
    }
}